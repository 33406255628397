import React, { useState, useRef, useEffect } from "react";
import lightOrDark from "../utils/lightOrDark";

const Skills = ({ skills, setSkills, language, accentColor }) => {
  // const ITEMS = skills?.length > 0 ? skills : [];
  // console.log(skills);
  const draggingPos = useRef(null);
  const dragOverPos = useRef(null);
  const newItemInput = useRef(null);
  const [items, setItems] = useState();
  const [newItem, setNewItem] = useState();
  const [accentShade, setAccentShade] = useState("light");

  // console.log(items);

  useEffect(() => {
    setAccentShade(lightOrDark(accentColor));
  }, [accentColor]);

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    dragOverPos.current = position;
    const newItems = [...items];
    const draggingItem = newItems[draggingPos.current];
    if (!draggingItem) return;

    newItems.splice(draggingPos.current, 1);
    newItems.splice(dragOverPos.current, 0, draggingItem);

    const reorderedItems = newItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    draggingPos.current = position;
    dragOverPos.current = null;

    setItems(reorderedItems);
    setSkills(reorderedItems);
  };

  function addNewItemFun() {
    newItemInput.current.value = "";
    const newId = parseInt(items.length + 1);
    console.log(`newId: ${newId}`);
    const tempItemsArray = [...items];
    tempItemsArray.push({
      id: newId,
      label: `${newItem}`,
      order: newId,
    });
    setItems(tempItemsArray);
    setSkills(tempItemsArray);
  }

  function issueNewIdsAndOrders(arr) {
    console.log(arr);
    let i = 0;
    const newArr = arr.map((item, index) => ({
      ...item,
      id: index,
      order: index,
    }));
    setItems(newArr);
    setSkills(newArr);
  }

  function deleteItem(id) {
    id = parseInt(id);
    // console.log(tempId);
    console.log(id);
    const newArray = items.filter((item) => item.id !== id);
    // console.log(newArray);
    issueNewIdsAndOrders(newArray);
  }
  useEffect(() => {
    if (skills?.length > 0) setItems(skills?.sort((a, b) => a.order - b.order));
    else setItems([]);
  }, [skills]);

  useEffect(() => {
    if (newItemInput.current?.value === "") return;
    const getKey = (e) => {
      if (e.key === "Enter") {
        addNewItemFun();
      }
    };
    document.addEventListener("keydown", getKey);
    return () => document.removeEventListener("keydown", getKey);
  });
  return (
    <>
      <div>
        <div className="container">
          {items?.map((item, index) => (
            <div
              key={item.id}
              className="item2"
              style={{ backgroundColor: accentColor }}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragEnter={() => handleDragEnter(index)}
              onDragOver={(e) => e.preventDefault()}
            >
              <span
                style={{ color: accentShade === "dark" ? "white" : "black" }}
              >
                {item.label}
              </span>
              <div
                className="deleteItem"
                onClick={() => deleteItem(`${item.id}`)}
                style={{ color: accentShade === "dark" ? "white" : "black" }}
              >
                X
              </div>
            </div>
          ))}
        </div>
        <br />
        <div style={{ marginTop: "0px" }}>
          <input
            className="w3-input w3-border"
            type="text"
            ref={newItemInput}
            onChange={(e) => setNewItem(e.target.value)}
          />
          <br />
          <button
            disabled={newItemInput.current?.value === ""}
            onClick={addNewItemFun}
            className="w3-button w3-gray"
          >
            Add new item
          </button>
          <br />
          <br />
          <br />
        </div>
      </div>
      {/* {skills?.length > 0 && (
        <>
          <p style={{ marginTop: "-21px" }}> This how your skills will show</p>
          <div className="container">
            {items?.map((item, index) => (
              <div
                key={item.id}
                className="itemInContainer"
                style={{ backgroundColor: accentColor }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </>
      )} */}
    </>
  );
};

export default Skills;
