import React from "react";
import parse from "html-react-parser";

const PublicFolioProjects = ({ projectsArr, accentColor, accentShade }) => {
  return (
    <div className="w3-container w3-card w3-white w3-margin-bottom">
      <h2 className="w3-text-grey w3-padding-16">
        <i
          className="fa-solid fa-compass-drafting fa-fw w3-margin-right w3-xxlarge"
          style={{ color: accentColor }}
        ></i>
        Projects
      </h2>

      {projectsArr
        .slice(0)
        .reverse()
        .map((project) => (
          <div className="w3-container" key={project.id}>
            <h5 className="w3-opacity">
              <b>{project.projectName}</b>
            </h5>
            <h6 style={{ color: accentColor, fontWeight: "bold" }}>
              <i className="fa fa-calendar fa-fw w3-margin-right"></i>
              Year: {project.projectYear}
            </h6>
            <div>{parse(project.projectDetails)}</div>
            <hr />
          </div>
        ))}
    </div>
  );
};

export default PublicFolioProjects;
