const PublicFolio_Languages = ({ languagesArr, accentColor, accentShade }) => {
  return (
    <div className="w3-container">
      <p className="w3-large w3-text-theme">
        <b>
          <i
            className="fa fa-globe fa-fw w3-margin-right"
            style={{ color: accentColor }}
          ></i>
          Languages
        </b>
      </p>
      {languagesArr.map((lang) => (
        <div key={lang.id}>
          <p>{lang.name}</p>
          <div
            className="w3-light-grey w3-round-xlarge"
            style={{ marginTop: "-15px" }}
          >
            <div
              className="w3-round-xlarge"
              style={{
                height: 27,
                width: `${lang.level}%`,
                backgroundColor: accentColor,
              }}
            ></div>
          </div>
        </div>
      ))}

      <br />
    </div>
  );
};

export default PublicFolio_Languages;
