import React, { useState } from "react";
import spinner from "../media/Icon_spinner.gif";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Nav from "../components/Nav";
import MainUI from "./MainUI";

const axiosAPI = process.env.REACT_APP_API_URL;

const LogIn = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    // e.preventDefault();
    await setIsSubmitting((prev) => !prev);
    setError(false);
    setErrorMessage(null);
    try {
      await getUser();
    } catch (e) {
      setError(true);
      setErrorMessage("something went wrong. Please try again later");
    }
  };

  const handleKeyStroke = (event) => {
    // console.log("inside keystroke: ", event);
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      // setIsSubmitting(true);
      handleSubmit();
    }
  };

  async function getUser() {
    try {
      await axios
        .post(`${axiosAPI}login/`, {
          params: { user, password },
        })
        .then((response) => {
          const folioName = response.data.folioName;
          // console.log(response);
          // console.log(folioName);
          navigate("/clientDashboard/", {
            state: { folioName },
          });
        });
    } catch (err) {
      // console.log("Error from Server: ", err.response.data);
      if (err.response.status === 404)
        setErrorMessage("Something went wrong. Please try again later");
      else {
        setErrorMessage(err.response.data);
      }
      setError(true);
      setIsSubmitting(false);
    }
  }

  return (
    <MainUI>
      <Nav />
      <div className=" w3-light-grey w3-margin-top w3-padding">
        <h1>Log IN</h1>

        {error && (
          <div className="w3-panel w3-red">
            <h3>Error!</h3>
            <p>
              Unable to log you in.{" "}
              {errorMessage ? errorMessage : "Check username and password"}
            </p>
          </div>
        )}
        {isSubmitting && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <h3>Loading..</h3> */}
            <i className="fa-solid fa-spinner fa-spin fa-2x"></i>
          </div>
        )}

        <div className="w3-container">
          <div className="w3-row w3-section">
            <label htmlFor="folioName">
              FolioName{" "}
              <input
                className="w3-input w3-border"
                name="folioName"
                type="text"
                placeholder="Profile Name"
                onChange={(e) => setUser(e.target.value.toLowerCase())}
                onKeyDown={(e) => handleKeyStroke(e)}
              />
            </label>
          </div>
          <div className="w3-row w3-section">
            <label htmlFor="password">
              Password{" "}
              <input
                className="w3-input w3-border"
                name="password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => handleKeyStroke(e)}
              />
            </label>
          </div>
        </div>
        <button
          className="w3-button w3-block w3-section w3-blue w3-ripple w3-padding"
          onClick={(e) => {
            // setIsSubmitting(true);
            // e.preventDefault();
            handleSubmit(e);
          }}
        >
          Submit
        </button>
      </div>
    </MainUI>
  );
};

export default LogIn;
