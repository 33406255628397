import React, { useState } from "react";

const SocialMedia = ({
  language,
  accentColor,
  mediaFaceBook,
  setMediaFaceBook,
  mediaLinkedIn,
  setMediaLinkedIn,
  mediaYouTube,
  setMediaYouTube,
  mediaTwitter,
  setMediaTwitter,
  mediaInstagram,
  setMediaInstagram,
}) => {
  return (
    <div>
      <label>
        FaceBook{" "}
        <i
          className="w3-xxlarge fa-brands fa-square-facebook"
          style={{ color: accentColor }}
        ></i>
        <input
          type="text"
          className="w3-input w3-border"
          name="mediaFaceBook"
          value={mediaFaceBook}
          onChange={(e) => {
            setMediaFaceBook(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        LinkedIn{" "}
        <i
          className="w3-xxlarge fa-brands fa-linkedin"
          style={{ color: accentColor }}
        ></i>
        <input
          type="text"
          className="w3-input w3-border"
          name="mediaLinkedIn"
          value={mediaLinkedIn}
          onChange={(e) => {
            setMediaLinkedIn(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        YouTube{" "}
        <i
          className="w3-xxlarge fa-brands fa-youtube"
          style={{ color: accentColor }}
        ></i>
        <input
          type="text"
          className="w3-input w3-border"
          name="mediaYouTube"
          value={mediaYouTube}
          onChange={(e) => {
            setMediaYouTube(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        X-Twitter{" "}
        <i
          className="w3-xxlarge fa-brands fa-square-x-twitter"
          style={{ color: accentColor }}
        ></i>
        <input
          type="text"
          className="w3-input w3-border"
          name="mediaTwitter"
          value={mediaTwitter}
          onChange={(e) => {
            setMediaTwitter(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Instagram{" "}
        <i
          className="w3-xxlarge fa-brands fa-square-instagram"
          style={{ color: accentColor }}
        ></i>
        <input
          type="text"
          className="w3-input w3-border"
          name="mediaInstagram"
          value={mediaInstagram}
          onChange={(e) => {
            setMediaInstagram(e.target.value);
          }}
        />
      </label>
    </div>
  );
};

export default SocialMedia;
