import React, { useEffect, useState } from "react";
// import { UserContext } from "../pages/UserContext.js";
import { useNavigate } from "react-router-dom";
import ExpertiseAndLanguages from "../components/ExpertiseAndLanguages";
import PersonalInfo from "../components/PersonalInfo";
import Skills from "../components/Skills";
import ImageUpload from "../components/ImageUpload";
import WorkExperienceAndEducation from "../components/WorkExperienceAndEducation.jsx";
import Project from "../components/Project.jsx";
import SocialMedia from "../components/SocialMedia.jsx";
import setAllFlagsOff from "../utils/setAllFlagsOff";
import parse from "html-react-parser";
import axios from "axios";
import no_picture from "../media/no_picture.png";
import Nav from "../components/Nav";
import MainUI from "./MainUI";
import ClientDashboardNav from "../components/ClientDashboardNav";

const axiosAPI = process.env.REACT_APP_API_URL;

const CreateFolio = ({ edit, folioObj }) => {
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [folioName, setFolioName] = useState("");
  const [phone, setPhone] = useState("");
  const [picture, setPicture] = useState(
    () => edit && (folioObj.picture ? folioObj.picture : no_picture)
  );
  const [expertises, setExpertise] = useState([]);
  const [addExpertiseFlag, setAddExpertiseFlag] = useState(false);
  const [skills, setSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [addLanguageFlag, setAddLanguageFlag] = useState(false);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [addWorkExperienceFlag, setAddWorkExperienceFlag] = useState(false);
  const [education, setEducation] = useState([]);
  const [addEducationFlag, setAddEducationFlag] = useState(false);
  const [projects, setProjects] = useState([]);
  const [addProjectFlag, setAddProjectFlag] = useState(false);
  const [mediaFaceBook, setMediaFaceBook] = useState("");
  const [mediaLinkedIn, setMediaLinkedIn] = useState("");
  const [mediaYouTube, setMediaYouTube] = useState("");
  const [mediaTwitter, setMediaTwitter] = useState("");
  const [mediaInstagram, setMediaInstagram] = useState("");
  const [errorCreatingProfile, setErrorCreatingProfile] = useState(false);
  const [messageFromServer, setMessageFromServer] = useState("");
  const [errorMissingProfile, setErrorMissingProfile] = useState();
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [editFolioSuccessfully, setEditFolioSuccessfully] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showEmailCode, setShowEmailCode] = useState("");
  const [showPhoneCode, setShowPhoneCode] = useState("");
  const [accentColor, setAccentColor] = useState("#4c95ce");
  const [language, setLanguage] = useState("en");
  const [personalInfoDivInner, setPersonalInfoDivInner] = useState(true);
  const [uploadPictureDivInner, setUploadPictureDivInner] = useState(true);
  const [socialMediaDivInner, setSocialMediaDivInner] = useState(false);
  const [expertiseDivInner, setExpertiseDivInner] = useState(false);
  const [skillsDivInner, setSkillsDivInner] = useState(false);
  const [languagesDivInner, setLanguagesDivInner] = useState(false);
  const [experienceDivInner, setExperienceDivInner] = useState(false);
  const [educationDivInner, setEducationDivInner] = useState(false);
  const [projectsDivInner, setProjectsDivInner] = useState(false);
  const [expandAllSections, setExpandAllSections] = useState(true);

  /* This array contains all flags of the different sections. It will be passed to the function setAllFlagsOff from "../utils/setAllFlagsOff" This function will turn all the flags in this array off. The second argument is the flag that needs to be turned on.
   */
  const allFlagsArr = [
    setAddExpertiseFlag,
    setAddLanguageFlag,
    setAddWorkExperienceFlag,
    setAddEducationFlag,
    setAddProjectFlag,
  ];

  const navigate = useNavigate();

  // const user = useContext(UserContext);
  // console.log(user.name);

  // console.log("edit: ", edit);
  // console.log("Picture: ", folioObj?.picture);
  // console.log("Picture2222: ", picture);

  useEffect(() => {
    if (
      folioName &&
      name &&
      email &&
      job &&
      location &&
      password &&
      confirmPassword &&
      !confirmPasswordError
    )
      setErrorMissingProfile(false);
    else setErrorMissingProfile(true);
    // console.log(
    //   "Boolean(XXXXX): ",
    //   Boolean(name),
    //   Boolean(folioName),
    //   Boolean(password),
    //   Boolean(job),
    //   Boolean(location),
    //   Boolean(email),
    //   Boolean(!confirmPasswordError)
    // );
    // console.log(`errorMissingProfile: ${errorMissingProfile}`);
  }, [
    folioName,
    name,
    password,
    confirmPassword,
    email,
    job,
    location,
    errorMissingProfile,
    confirmPasswordError,
    edit,
  ]);

  useEffect(() => {
    if (edit) {
      setFolioName(folioObj.folioName);
      setEmail(folioObj.email);
      setName(folioObj.name);
      setJob(folioObj.job);
      setLocation(folioObj.location);
      setPhone(folioObj.phone);
      setExpertise(folioObj.expertises || []);
      setSkills(folioObj.skills || []);
      setLanguages(folioObj.languages);
      setWorkExperiences(folioObj.workExperiences);
      setEducation(folioObj.education);
      setProjects(folioObj?.projects || []);
      setMediaFaceBook(folioObj?.mediaFaceBook || "");
      setMediaLinkedIn(folioObj?.mediaLinkedIn || "");
      setMediaYouTube(folioObj?.mediaYouTube || "");
      setMediaTwitter(folioObj?.mediaTwitter || "");
      setMediaInstagram(folioObj?.mediaInstagram || "");
      setShowEmailCode(folioObj?.showEmailCode);
      setShowPhoneCode(folioObj?.showPhoneCode);
      setAccentColor(folioObj.accentColor ? folioObj.accentColor : "#4c95ce");
      setLanguage(folioObj.language || "en");
    }
  }, [edit]);

  const handlerAddNewExpertise = (obj) => {
    setAddExpertiseFlag(false);
    let lastId;
    if (expertises.length === 0) {
      lastId = 0;
    } else {
      lastId = expertises[expertises.length - 1].id;
    }
    setExpertise([
      ...expertises,
      {
        id: lastId + 1,
        name: obj.name,
        level: obj.level,
      },
    ]);
  };

  const handlerAddNewLanguage = (obj) => {
    setAddLanguageFlag(false);
    let lastId;
    if (languages.length === 0) {
      lastId = 0;
    } else {
      lastId = languages[languages.length - 1].id;
    }
    setLanguages([
      ...languages,
      {
        id: lastId + 1,
        name: obj.name,
        level: obj.level,
      },
    ]);
  };

  const handlerAddNewWork = (obj) => {
    setAddWorkExperienceFlag(false);
    let lastId;
    if (workExperiences.length === 0) {
      lastId = 0;
    } else {
      lastId = workExperiences[workExperiences.length - 1].id;
    }
    setWorkExperiences([
      ...workExperiences,
      {
        id: lastId + 1,
        title: obj.title,
        company: obj.company,
        fromMonth: obj.fromMonth,
        fromYear: obj.fromYear,
        toMonth: obj.toMonth,
        toYear: obj.toYear,
        isCurrent: obj.isCurrent,
        workDetail: obj.workDetail,
      },
    ]);
  };

  const handlerAddNewEducation = (obj) => {
    setAddEducationFlag(false);
    let lastId;
    if (education.length === 0) {
      lastId = 0;
    } else {
      lastId = education[education.length - 1].id;
    }
    setEducation([
      ...education,
      {
        id: lastId + 1,
        title: obj.title,
        fromMonth: obj.fromMonth,
        fromYear: obj.fromYear,
        toMonth: obj.toMonth,
        toYear: obj.toYear,
        isCurrent: obj.isCurrent,
        workDetail: obj.workDetail,
      },
    ]);
  };

  const handlerAddNewProject = (obj) => {
    setAddProjectFlag(false);
    let lastId;
    if (projects.length === 0) {
      lastId = 0;
    } else {
      lastId = projects[projects.length - 1].id;
    }
    setProjects([
      ...projects,
      {
        id: lastId + 1,
        projectName: obj.projectName,
        projectYear: obj.projectYear,
        projectDetails: obj.projectDetails,
      },
    ]);
  };

  const handlerCancelNewExpertise = (event) => {
    setAddExpertiseFlag(false);
  };

  const handlerCancelNewLanguage = (event) => {
    setAddLanguageFlag(false);
  };

  const handlerCancelNewWork = (event) => {
    setAddWorkExperienceFlag(false);
  };

  const handlerCancelNewEducation = (event) => {
    setAddEducationFlag(false);
  };
  const handlerCancelNewProject = (event) => {
    setAddProjectFlag(false);
  };

  const handleSubmit = async (event, editFlag) => {
    setIsProcessing(true);
    event.preventDefault();
    setEditFolioSuccessfully(false);
    // console.log("editFlag", editFlag);
    window.scrollTo(0, 0);
    setErrorCreatingProfile(false);
    setMessageFromServer("");
    // console.log(`password: ${password}`);
    let portfolioJsonObj = {};
    portfolioJsonObj.name = name || "";
    portfolioJsonObj.job = job || "";
    portfolioJsonObj.phone = phone || "";
    portfolioJsonObj.email = email || "";
    portfolioJsonObj.folioName = folioName || "";
    portfolioJsonObj.password = password || "";
    portfolioJsonObj.location = location || "";
    portfolioJsonObj.expertises = expertises;
    portfolioJsonObj.skills = skills || [];
    portfolioJsonObj.languages = languages;
    portfolioJsonObj.workExperiences = workExperiences;
    portfolioJsonObj.education = education;
    portfolioJsonObj.projects = projects;
    portfolioJsonObj.showEmailCode = showEmailCode || "";
    portfolioJsonObj.showPhoneCode = showPhoneCode || "";
    portfolioJsonObj.mediaFaceBook = mediaFaceBook || "";
    portfolioJsonObj.mediaLinkedIn = mediaLinkedIn || "";
    portfolioJsonObj.mediaYouTube = mediaYouTube || "";
    portfolioJsonObj.mediaTwitter = mediaTwitter || "";
    portfolioJsonObj.mediaInstagram = mediaInstagram || "";
    portfolioJsonObj.accentColor = accentColor || "4c95ce";
    portfolioJsonObj.language = language;
    portfolioJsonObj.picture = picture || "";

    // console.log(portfolioJsonObj);
    try {
      const response = await axios.post(`${axiosAPI}addFolio`, {
        portfolioJsonObj,
        editFlag,
      });
      // console.log(response);
      // console.log(response.status);
      // console.log(response.data);
      // setMessageFromServer(response.data.jMessage);
      if (!editFlag) {
        navigate("/folioCreatedSuccessfully", {
          state: { folioName: folioName },
        });
      } else {
        console.log("Edit is successful");
        setEditFolioSuccessfully(true);
        setIsProcessing(false);
      }
    } catch (error) {
      // console.log(error);
      setErrorCreatingProfile(true);
      setMessageFromServer(error.response.data);
    }
  };

  const handleExpandContractAllSections = () => {
    const allSectionsSetters = [
      setPersonalInfoDivInner,
      setUploadPictureDivInner,
      setSocialMediaDivInner,
      setExpertiseDivInner,
      setSkillsDivInner,
      setLanguagesDivInner,
      setExperienceDivInner,
      setEducationDivInner,
      setProjectsDivInner,
    ];
    setExpandAllSections(!expandAllSections);
    allSectionsSetters.forEach((setter) => {
      setter(!expandAllSections);
    });
  };

  return (
    <MainUI>
      {!edit && <Nav />}
      {edit && <ClientDashboardNav folioName={folioName} />}
      {edit && language === "en" && (
        <h1 className="w3-center">Edit your portfolio</h1>
      )}
      {edit && language === "fr" && (
        <h1 className="w3-center">Modifiez votre portfolio</h1>
      )}
      {!edit && language === "en" && (
        <h1 className="w3-center">Your Portfolio @ FolioUp.me</h1>
      )}
      {!edit && language === "fr" && (
        <h1 className="w3-center">Votre Portfolio @ FolioUp.me</h1>
      )}
      {isProcessing && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <h3>Loading..</h3> */}
          <i className="fa-solid fa-spinner fa-spin fa-2x"></i>
        </div>
      )}
      {editFolioSuccessfully && (
        <h1 className="w3-center w3-panel w3-green">Edit is successful</h1>
      )}
      {messageFromServer && (
        <h1 className="w3-center w3-panel w3-red">{messageFromServer}</h1>
      )}

      <div className="w3-padding-large">
        Language / Langue
        <select
          defaultValue={language}
          className="w3-select w3-border"
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        >
          <option value="en">English</option>
          <option value="fr">Francais</option>
        </select>
      </div>

      <div id="mainDiv" className="w3-container w3-light-grey">
        {/* ****************************    Expand / contract all   ****************************** */}
        <div
          className="createFolioSectionHeader w3-container w3-card w3-white w3-margin-bottom"
          onClick={handleExpandContractAllSections}
        >
          <h3>
            {language === "en" && "Expand / contract ALL sections"}
            {language === "fr" && "Développer / contracter TOUTES les sections"}
          </h3>

          <span className="createFolioSectionHeaderExpandExtractSign">
            {!expandAllSections && <i className="fa-solid fa-plus fa-3x"></i>}
            {expandAllSections && <i className="fa-solid fa-minus fa-3x"></i>}
          </span>
        </div>

        {/* ********************************    Personal Info        *********************************** */}
        <div
          id="personalInfoDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setPersonalInfoDivInner(!personalInfoDivInner)}
          >
            <h3>
              {language === "en" && "Personal Info"}
              {language === "fr" && "Informations personnelles"}
            </h3>

            <span className="createFolioSectionHeaderExpandExtractSign">
              {!personalInfoDivInner && (
                <i className="fa-solid fa-plus fa-3x"></i>
              )}
              {personalInfoDivInner && (
                <i className="fa-solid fa-minus fa-3x"></i>
              )}
            </span>
          </div>
          {personalInfoDivInner && (
            <div id="personalInfoDivInner">
              <PersonalInfo
                folioName={folioName}
                email={email}
                name={name}
                job={job}
                password={password}
                confirmPassword={confirmPassword}
                location={location}
                phone={phone}
                setName={setName}
                setJob={setJob}
                setLocation={setLocation}
                setEmail={setEmail}
                setPhone={setPhone}
                setFolioName={setFolioName}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                confirmPasswordError={confirmPasswordError}
                setConfirmPasswordError={setConfirmPasswordError}
                edit={edit}
                showEmailCode={showEmailCode}
                showPhoneCode={showPhoneCode}
                setShowEmailCode={setShowEmailCode}
                setShowPhoneCode={setShowPhoneCode}
                accentColor={accentColor}
                setAccentColor={setAccentColor}
                language={language}
              />
              <hr />
            </div>
          )}
        </div>
        {/* ************************************    Your Picture        s********************** */}
        <div
          id="uploadPictureDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setUploadPictureDivInner(!uploadPictureDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-camera"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Picture"}
              {language === "fr" && "Photo"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!uploadPictureDivInner && (
                <i className="fa-solid fa-plus fa-3x"></i>
              )}
              {uploadPictureDivInner && (
                <i className="fa-solid fa-minus fa-3x"></i>
              )}
            </span>
          </div>
          {uploadPictureDivInner && (
            <div id="uploadPictureDivInner">
              {edit && (
                <div style={{ width: 300 }}>
                  <br />
                  <img
                    src={folioObj?.picture ? folioObj.picture : no_picture}
                    style={{ width: `100%` }}
                    alt="avatar"
                  />
                  <hr />
                </div>
              )}
              <h4>
                <i
                  className="fa-solid fa-upload"
                  style={{ color: accentColor }}
                ></i>{" "}
                {language === "en" && "Upload your beautiful picture"}
                {language === "fr" && "Téléchargez votre belle photo"}
              </h4>
              <ImageUpload setPicture={setPicture} language={language} />
              <hr />
            </div>
          )}
        </div>
        {/* **************************************    Social Media       ****************************** */}
        <div
          id="socialMediaDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setSocialMediaDivInner(!socialMediaDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-circle-nodes"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Social Media"}
              {language === "fr" && "Médias sociaux"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!socialMediaDivInner && (
                <i className="fa-solid fa-plus fa-3x"></i>
              )}
              {socialMediaDivInner && (
                <i className="fa-solid fa-minus fa-3x"></i>
              )}
            </span>
          </div>
          {socialMediaDivInner && (
            <div id="socialMediaDivInner">
              <SocialMedia
                language={language}
                accentColor={accentColor}
                mediaFaceBook={mediaFaceBook}
                setMediaFaceBook={setMediaFaceBook}
                mediaLinkedIn={mediaLinkedIn}
                setMediaLinkedIn={setMediaLinkedIn}
                mediaYouTube={mediaYouTube}
                setMediaYouTube={setMediaYouTube}
                mediaTwitter={mediaTwitter}
                setMediaTwitter={setMediaTwitter}
                mediaInstagram={mediaInstagram}
                setMediaInstagram={setMediaInstagram}
              />
              <hr />
            </div>
          )}
        </div>
        {/* **************************************    Expertise        *********************************** */}
        <div
          id="expertiseDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setExpertiseDivInner(!expertiseDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-medal"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Expertise"}
              {language === "fr" && "Expertise"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!expertiseDivInner && <i className="fa-solid fa-plus fa-3x"></i>}
              {expertiseDivInner && <i className="fa-solid fa-minus fa-3x"></i>}
            </span>
          </div>

          {expertiseDivInner && (
            <div id="expertiseDivInner">
              {expertises?.map((expertise) => (
                <div key={expertise.id}>
                  {/* <p>expertise id: {expertise.id}</p> */}
                  <p>
                    <span className="w3-opacity">
                      {language === "en" && "Expertise"}
                      {language === "fr" && "Expertise"}:
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {expertise.name}
                    </span>
                  </p>
                  <p>
                    <span className="w3-opacity">
                      {language === "en" && "Level"}
                      {language === "fr" && "Niveau"}:{" "}
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {expertise.level}%
                    </span>
                  </p>
                  <button
                    type="button"
                    className="w3-button w3-red"
                    onClick={() =>
                      setExpertise(
                        expertises.filter(
                          (expertiseTemp) => expertiseTemp.id !== expertise.id
                        )
                      )
                    }
                  >
                    {language === "en" && "Delete"}
                    {language === "fr" && "Effacer"}
                  </button>
                </div>
              ))}
              <br />
              <div className="besideEachOther">
                {expertises?.length === 0 && (
                  <p>
                    {language === "en" && "No Expertise"}
                    {language === "fr" && "No Expertise"}
                  </p>
                )}
                <button
                  className="w3-button w3-gray"
                  onClick={() => {
                    setAllFlagsOff(allFlagsArr, setAddExpertiseFlag);
                  }}
                >
                  {language === "en" && "Add Expertise"}
                  {language === "fr" && "Ajouter de l'expertise"}
                </button>
              </div>
              {addExpertiseFlag && (
                <ExpertiseAndLanguages
                  onCancel={handlerCancelNewExpertise}
                  onSubmit={handlerAddNewExpertise}
                  language={language}
                  title={
                    language === "en"
                      ? "Expertise"
                      : language === "fr"
                      ? " Expertise "
                      : "Add something here"
                  }
                />
              )}
              <hr />
            </div>
          )}
        </div>

        {/* **************************************    SKILLS        *********************************** */}
        <div
          id="skillsDiv"
          className="w3-container  w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setSkillsDivInner(!skillsDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-star-of-life"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Skills"}
              {language === "fr" && "Compétences"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!skillsDivInner && <i className="fa-solid fa-plus fa-3x"></i>}
              {skillsDivInner && <i className="fa-solid fa-minus fa-3x"></i>}
            </span>
          </div>
          {skillsDivInner && (
            <div id="skillsDivInner">
              <h5 className="w3-text-red w3-hover-red w3-wide">
                {language === "en" &&
                  "Enter skill in the textbox below. You can rearrange their order. Click on 'X' to delete"}
                {language === "fr" &&
                  "Saisissez la compétence dans la zone de texte ci-dessous. Vous pouvez réorganiser leur ordre. Cliquez sur 'X' pour supprimer"}
              </h5>
              <Skills
                skills={skills}
                setSkills={setSkills}
                language={language}
                accentColor={accentColor}
              />
              <hr />
            </div>
          )}
        </div>
        {/* **************************************    Languages        *********************************** */}
        <div
          id="languagesDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setLanguagesDivInner(!languagesDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-globe"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Languages"}
              {language === "fr" && "Langues"}{" "}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!languagesDivInner && <i className="fa-solid fa-plus fa-3x"></i>}
              {languagesDivInner && <i className="fa-solid fa-minus fa-3x"></i>}
            </span>
          </div>
          {languagesDivInner && (
            <div id="languagesDivInner">
              {languages.map((languageTemp) => (
                <div key={languageTemp.id}>
                  {/* <p>language id: {language.id}</p> */}
                  <p>
                    <span className="w3-opacity">
                      {language === "en" && "Language"}
                      {language === "fr" && "Langue"}:
                    </span>{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {languageTemp.name}
                    </span>
                  </p>
                  <p>
                    <span className="w3-opacity">
                      {language === "en" && "Level"}
                      {language === "fr" && "Niveau"}:
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {languageTemp.level}%
                    </span>
                  </p>
                  <button
                    type="button"
                    className="w3-button w3-red"
                    onClick={() =>
                      setLanguages(
                        languages.filter(
                          (languageFilter) =>
                            languageFilter.id !== languageTemp.id
                        )
                      )
                    }
                  >
                    {language === "en" && "Delete"}
                    {language === "fr" && "Effacer"}
                  </button>
                </div>
              ))}
              <br />
              <div className="besideEachOther">
                {languages.length === 0 && (
                  <p>
                    {language === "en" && "No Languages"}
                    {language === "fr" && "No langues"}
                  </p>
                )}
                <button
                  className="w3-button w3-grey"
                  onClick={() => {
                    setAllFlagsOff(allFlagsArr, setAddLanguageFlag);
                  }}
                >
                  {language === "en" && "Add a Language"}
                  {language === "fr" && "Ajouter une langue"}
                </button>
              </div>

              {addLanguageFlag && (
                <ExpertiseAndLanguages
                  onCancel={handlerCancelNewLanguage}
                  onSubmit={handlerAddNewLanguage}
                  title={
                    language === "en"
                      ? "Language"
                      : language === "fr"
                      ? "Langue"
                      : "Add something here"
                  }
                  language={language}
                />
              )}

              <br />
              <hr />
            </div>
          )}
        </div>
        {/* **************************************    Experience        *********************************** */}
        <div
          id="experienceDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setExperienceDivInner(!experienceDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-briefcase"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Experience"}
              {language === "fr" && "Expérience"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!experienceDivInner && (
                <i className="fa-solid fa-plus fa-3x"></i>
              )}
              {experienceDivInner && (
                <i className="fa-solid fa-minus fa-3x"></i>
              )}
            </span>
          </div>
          {experienceDivInner && (
            <div id="experienceDivInner">
              <h5 className="w3-text-red w3-hover-red w3-wide">
                {language === "en" &&
                  "Enter them in reverse chronological order. Older ones first"}
                {language === "fr" &&
                  "Inscrivez-les dans l'ordre chronologique inverse. Les plus anciens d'abord"}
              </h5>

              {workExperiences
                .slice(0)
                .reverse()
                .map((work) => (
                  <div key={work.id}>
                    {/* <p>work id: {work.id}</p> */}
                    <p>
                      <span className="w3-opacity">
                        {language === "en" && "Company"}
                        {language === "fr" && "Entreprise"}:
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {work.company}
                      </span>
                    </p>
                    <p>
                      <span className="w3-opacity">
                        {language === "en" && "Title"}
                        {language === "fr" && "Titre"}:
                      </span>{" "}
                      <span style={{ fontWeight: "bold" }}>{work.title}</span>
                    </p>
                    <p>
                      <i className="w3-xxlarge fa-solid fa-calendar-days"></i>{" "}
                      {work.fromMonth} {work.fromYear} -{" "}
                      {work.isCurrent ? (
                        <>
                          {language === "en"
                            ? "current"
                            : language === "fr"
                            ? "présent"
                            : "Enter something else"}
                        </>
                      ) : (
                        <>
                          {work.toMonth} {work.toYear}
                        </>
                      )}
                      {/* {!work.current && work.toMonth, work.toYear} */}
                    </p>
                    <div>{parse(work.workDetail)}</div>
                    <button
                      className="w3-button w3-red"
                      type="button"
                      onClick={() =>
                        setWorkExperiences(
                          workExperiences.filter(
                            (workTemp) => workTemp.id !== work.id
                          )
                        )
                      }
                    >
                      {language === "en" && "Delete"}
                      {language === "fr" && "Effacer"}
                    </button>
                  </div>
                ))}
              <br />
              <div className="besideEachOther">
                {workExperiences.length === 0 && (
                  <p>
                    {language === "en" && "No Work Experince"}
                    {language === "fr" && "No Expérience"}
                  </p>
                )}
                <button
                  className="w3-button w3-gray"
                  onClick={() => {
                    setAllFlagsOff(allFlagsArr, setAddWorkExperienceFlag);
                  }}
                >
                  {language === "en" && "Add Work Experince"}
                  {language === "fr" && "Ajouter une expérience"}
                </button>
              </div>
              {addWorkExperienceFlag && (
                <WorkExperienceAndEducation
                  param="work"
                  onCancel={handlerCancelNewWork}
                  onSubmit={handlerAddNewWork}
                  language={language}
                />
              )}
              <br />
              <hr />
            </div>
          )}
        </div>
        {/* **************************************    Education        *********************************** */}
        <div
          id="educationDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setEducationDivInner(!educationDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-graduation-cap"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Education"}
              {language === "fr" && "L'éducation"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!educationDivInner && <i className="fa-solid fa-plus fa-3x"></i>}
              {educationDivInner && <i className="fa-solid fa-minus fa-3x"></i>}
            </span>
          </div>
          {educationDivInner && (
            <div id="educationDivInner">
              <h5 className="w3-text-red w3-hover-red w3-wide">
                {language === "en" &&
                  "Enter them in reverse chronological order. Older ones first"}
                {language === "fr" &&
                  "Inscrivez-les dans l'ordre chronologique inverse. Les plus anciens d'abord"}
              </h5>

              {education
                .slice(0)
                .reverse()
                .map((oneEducation) => (
                  <div key={oneEducation.id}>
                    {/* <p>work id: {oneEducation.id}</p> */}
                    <p>
                      <span className="w3-opacity">
                        {language === "en" && "School or institute"}
                        {language === "fr" && "École ou institut"}:
                      </span>{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {oneEducation.title}
                      </span>
                    </p>
                    <p>
                      <i className="w3-xxlarge fa-solid fa-calendar-days"></i>{" "}
                      {oneEducation.fromMonth} {oneEducation.fromYear} -{" "}
                      {oneEducation.isCurrent ? (
                        "current"
                      ) : (
                        <>
                          {oneEducation.toMonth} {oneEducation.toYear}
                        </>
                      )}
                    </p>
                    <div>{parse(oneEducation.workDetail)}</div>
                    <button
                      className="w3-button w3-red"
                      type="button"
                      onClick={() =>
                        setEducation(
                          education.filter(
                            (educationTemp) =>
                              educationTemp.id !== oneEducation.id
                          )
                        )
                      }
                    >
                      {language === "en" && "Delete"}
                      {language === "fr" && "Effacer"}
                    </button>
                  </div>
                ))}
              <br />
              <div className="besideEachOther">
                {education.length === 0 && (
                  <p>
                    {language === "en" && "No Education"}
                    {language === "fr" && "Pas d'éducation"}
                  </p>
                )}
                <button
                  className="w3-button w3-gray"
                  onClick={() => {
                    setAllFlagsOff(allFlagsArr, setAddEducationFlag);
                  }}
                >
                  {language === "en" && "Add Education"}
                  {language === "fr" && "Ajouter de l'éducation"}
                </button>
              </div>
              {addEducationFlag && (
                <WorkExperienceAndEducation
                  param="education"
                  onCancel={handlerCancelNewEducation}
                  onSubmit={handlerAddNewEducation}
                  language={language}
                />
              )}
              <br />
              <hr />
            </div>
          )}
        </div>
        {/* **************************************    Projects        *********************************** */}
        <div
          id="projectsDiv"
          className="w3-container w3-card w3-white w3-margin-bottom"
        >
          <div
            className="createFolioSectionHeader"
            onClick={(e) => setProjectsDivInner(!projectsDivInner)}
          >
            <h3>
              <i
                className="fa-solid fa-compass-drafting"
                style={{ color: accentColor }}
              ></i>{" "}
              {language === "en" && "Projects"}
              {language === "fr" && "Projets"}
            </h3>
            <span className="createFolioSectionHeaderExpandExtractSign">
              {!projectsDivInner && <i className="fa-solid fa-plus fa-3x"></i>}
              {projectsDivInner && <i className="fa-solid fa-minus fa-3x"></i>}
            </span>{" "}
          </div>

          {projectsDivInner && (
            <div id="projectsDivInner">
              <h5 className="w3-text-red w3-hover-red w3-wide">
                {language === "en" &&
                  "Enter them in reverse chronological order. Older ones first"}
                {language === "fr" &&
                  "Inscrivez-les dans l'ordre chronologique inverse. Les plus anciens d'abord"}
              </h5>
              {projects
                .slice(0)
                .reverse()
                .map((project) => (
                  <div key={project.id}>
                    {/* <p>{`id: ${project.id}`}</p> */}
                    <p>
                      <span className="w3-opacity">
                        {language === "en" && "Project Name"}
                        {language === "fr" && "Nom du projet"}:
                      </span>{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {project.projectName}
                      </span>
                    </p>
                    <p>
                      <i className="w3-xxlarge fa-solid fa-calendar-days"></i>{" "}
                      {language === "en" && `Year: ${project.projectYear}`}
                      {language === "fr" && `Année: ${project.projectYear}`}
                    </p>
                    <div>{parse(project.projectDetails)}</div>
                    <button
                      className="w3-button w3-red"
                      type="button"
                      onClick={() =>
                        setProjects(
                          projects.filter(
                            (projectTemp) => projectTemp.id !== project.id
                          )
                        )
                      }
                    >
                      {language === "en" && "Delete"}
                      {language === "fr" && "Effacer"}
                    </button>
                  </div>
                ))}
              <br />
              <div className="besideEachOther">
                {projects.length === 0 && (
                  <p>
                    {language === "en" && "No Projects"}
                    {language === "fr" && "No Projets"}
                  </p>
                )}
                <button
                  className="w3-button w3-gray"
                  onClick={() => {
                    setAllFlagsOff(allFlagsArr, setAddProjectFlag);
                  }}
                >
                  {language === "en" && "Add a Project"}
                  {language === "fr" && "Ajouter un projet"}
                </button>
              </div>
              {addProjectFlag && (
                <Project
                  onCancel={handlerCancelNewProject}
                  onSubmit={handlerAddNewProject}
                  language={language}
                />
              )}
              <br />
              <hr />
            </div>
          )}
        </div>

        <br />
        <br />
        <br />

        {/* ***********************************    Submit / Edit button       ********************** */}

        {!edit && (
          <button
            onClick={(event) => handleSubmit(event, (edit = false))}
            type="submit"
            className="w3-button w3-block w3-section w3-blue w3-ripple w3-padding"
            disabled={errorMissingProfile}
          >
            Submit Your Portfolio
          </button>
        )}
        {edit && (
          <button
            onClick={(event) => handleSubmit(event, (edit = true))}
            type="submit"
            className="w3-button w3-block w3-section w3-blue w3-ripple w3-padding"
            disabled={errorMissingProfile}
          >
            Edit Your Portfolio
          </button>
        )}
      </div>
    </MainUI>
  );
};

export default CreateFolio;
