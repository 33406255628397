import React, { useState, useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageUpload = ({ setPicture, language }) => {
  const [imageURL, setImageURL] = useState();
  const [croppedImage, setCroppedImage] = useState();
  const cropperRef = useRef(null);

  const getCroppedImage = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      // console.log(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setPicture(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const getImageHandler = (e) => {
    // console.log(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className="w3-row">
      <input
        type="file"
        id="uploadButton"
        accept="image/png, image/jpeg, image/jpg"
        onChange={getImageHandler}
        style={{ display: "none" }}
      />
      <label htmlFor="uploadButton" className="w3-button w3-gray">
        {language === "en" && "Choose Your Picture"}
        {language === "fr" && "Choisissez votre photo"}
      </label>
      <br />
      <br />
      <br />
      <div className="w3-row">
        <div className="w3-container w3-half">
          {imageURL && (
            <div style={{ height: 500, width: 400 }}>
              <Cropper
                src={imageURL}
                style={{ height: 400, width: 400 }}
                dragMode="move"
                aspectRatio={3 / 4}
                autoCropArea="0.5"
                restore={false}
                guides={false}
                center={false}
                highlight={false}
                cropBoxMovable={false}
                cropBoxResizable={false}
                toggleDragModeOnDblclick={false}
                ref={cropperRef}
              />
              <br />
              <div className="w3-center">
                <button onClick={getCroppedImage} className="w3-button w3-gray">
                  Crop Image
                </button>{" "}
              </div>
            </div>
          )}
        </div>
        <div
          style={{ height: croppedImage ? "400px" : "0px" }}
          className="w3-container w3-half"
        >
          {croppedImage && (
            <img
              src={croppedImage}
              alt="cropped"
              style={{ height: "100%", border: "1px black solid" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
