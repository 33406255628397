import React, { useState, useEffect } from "react";
import ErrorComponent from "./ErrorComponent";

const ExpertiseAndLanguages = ({ onSubmit, onCancel, title, language }) => {
  const [newSkillName, setNewSkillName] = useState("");
  const [newSkillLevel, setNewSkillLevel] = useState("");
  const [errorArray, setErrorArray] = useState(false);

  const handleSubmit = () => {
    let errorArrayTemp = [];
    if (newSkillName.length === 0 && `${language}` === "en")
      errorArrayTemp.push(`${title} name should NOT be empty`);
    if (newSkillName.length === 0 && `${language}` === "fr")
      errorArrayTemp.push(`Le nom de la ${title} NE doit PAS être vide`);
    if (
      (newSkillLevel.length === 0 ||
        newSkillLevel < 0 ||
        newSkillLevel > 100) &&
      `${language}` === "en"
    )
      errorArrayTemp.push(`${title} level must be between 0 and 100`);
    if (
      (newSkillLevel.length === 0 ||
        newSkillLevel < 0 ||
        newSkillLevel > 100) &&
      `${language}` === "fr"
    )
      errorArrayTemp.push(
        `Le niveau de ${title} doit être compris entre 0 et 100`
      );

    setErrorArray(errorArrayTemp);
    if (errorArrayTemp.length > 0) return;

    const obj = {
      name: newSkillName,
      level: newSkillLevel,
    };
    onSubmit(obj);
  };
  useEffect(() => {
    const getKey = (e) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    };
    document.addEventListener("keydown", getKey);
    return () => document.removeEventListener("keydown", getKey);
  }, [newSkillName, newSkillLevel]);

  return (
    <div>
      {errorArray.length > 0 && (
        <ErrorComponent
          title="Skills"
          errorArray={errorArray}
          language={language}
        />
      )}
      {title}
      <input
        className="w3-input w3-border"
        type="text"
        name="name"
        onChange={(event) => setNewSkillName(event.target.value)}
      />
      <br />
      {language === "en" && `${title} level`}
      {language === "fr" && `Niveau de ${title}`}
      <input
        className="w3-input w3-border"
        type="number"
        name="level"
        onChange={(event) => setNewSkillLevel(event.target.value)}
      />
      <br />
      <div className="besideEachOther">
        <button onClick={handleSubmit} className="w3-button w3-gray">
          {language === "en" && `Add New ${title} ${newSkillName}`}
          {language === "fr" && `Ajouter une nouvelle ${title} ${newSkillName}`}
        </button>
        <button onClick={onCancel} className="w3-button w3-red">
          {language === "en" && "Cancel"}
          {language === "fr" && "Annuler"}
        </button>
      </div>
    </div>
  );
};

export default ExpertiseAndLanguages;
