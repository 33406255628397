import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../media/Logo-no-bg.png";

const ClientDashboardNav = ({ folioName }) => {
  const [loggedIn, setLoggedIn] = useState(true);
  return (
    <div>
      <div>
        <div className="w3-margin">
          <Link to="/">
            <img src={logo} style={{ width: 200 }} />
          </Link>
        </div>
        <div className="w3-bar w3-border w3-light-grey">
          <span className="w3-bar-item w3-left  w3-mobile">
            {loggedIn ? `Welcome ${folioName}` : ""}
          </span>

          <Link
            to="/login"
            replace
            style={{ background: "#8cc23f", color: "#fff" }}
            className="w3-bar-item w3-button w3-right w3-card  w3-mobile"
          >
            <strong>{loggedIn ? "Log out" : "Log in"} </strong>
          </Link>

          {
            <Link
              to={`/${folioName}`}
              style={{ background: "#4c95ce", color: "#fff" }}
              className="w3-bar-item w3-button w3-mobile w3-right"
            >
              Your online profile
            </Link>
          }
        </div>
      </div>
    </div>
  );
};

export default ClientDashboardNav;
