import React from "react";
import MainUI from "./MainUI";
import Nav from "../components/Nav";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  // console.error(error);

  return (
    <MainUI>
      <Nav />
      <div className="w3-center">
        <p style={{ fontSize: "90px", margin: "0" }}>Oops!</p>
        <p style={{ fontSize: "20px", margin: "0" }}>
          Sorry, an unexpected error has occurred.
        </p>
        <i>{error?.statusText || error?.message}</i>
      </div>
    </MainUI>
  );
};

export default ErrorPage;
