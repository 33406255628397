import React, { useState } from "react";
import { SketchPicker, ChromePicker } from "react-color";

const PersonalInfo = ({
  folioName,
  email,
  name,
  password,
  confirmPassword,
  job,
  location,
  phone,
  setName,
  setPassword,
  setConfirmPassword,
  setJob,
  setPhone,
  setEmail,
  setLocation,
  setFolioName,
  setConfirmPasswordError,
  confirmPasswordError,
  edit,
  showEmailCode,
  showPhoneCode,
  setShowEmailCode,
  setShowPhoneCode,
  accentColor,
  setAccentColor,
  language,
}) => {
  const [folioNameError, setFolioNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  // console.log("edit: ", edit);

  // console.log(language);

  function handlerFolioName(e) {
    setFolioNameError(false);
    const tempFolioName = e.target.value;
    setFolioName(tempFolioName);
    const regEx = /^[a-zA-Z0-9][a-zA-Z0-9-]+$/;
    if (!regEx.test(tempFolioName) || tempFolioName.length < 5)
      setFolioNameError(true);
  }

  function handlerEmail(e) {
    setEmailError(false);
    const email = e.target.value;
    const regEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regEx.test(email)) {
      setEmailError(false);
      setEmail(email.toLowerCase());
    } else setEmailError(true);
  }

  function handlerPassword(e) {
    setPasswordError(false);
    const regEx = /^\S{4,14}$/;
    if (regEx.test(e.target.value)) {
      setPasswordError(false);
      setPassword(e.target.value);
    } else {
      setPasswordError(true);
    }
  }

  function handlerConfirmPassword() {
    setConfirmPasswordError(false);
    if (password !== confirmPassword) setConfirmPasswordError(true);
  }

  return (
    <div>
      
      <h4>
        <i className="fa-solid fa-asterisk w3-text-red"></i>{" "}
        {language === "en" && "required field"}
        {language === "fr" && "Champ requis"}
      </h4>
      <br />
      {folioNameError && (
        <div className="w3-panel w3-red">
          {language === "en" && (
            <>
              <h3>Error!</h3>
              <ul>
                <li>Minimun 4 characters</li>
                <li>
                  No spaces, no special characters. Only letters, hyphens and
                  numbers
                </li>
              </ul>
            </>
          )}
          {language === "fr" && (
            <>
              <h3>Erreur!</h3>
              <ul>
                <li>Minimun 4 caractères</li>
                <li>
                  Pas d'espaces, pas de caractères spéciaux. Uniquement des
                  lettres, des traits d'union et des chiffres
                </li>
              </ul>
            </>
          )}
        </div>
      )}
      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa-solid fa-passport"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <span>https://folioUp.me/</span>
          <input
            readOnly={edit ? true : false}
            className="w3-input w3-border"
            name="folioName"
            type="text"
            placeholder={
              language === "en"
                ? "Your Portfolio Name"
                : language === "fr"
                ? "Le nom de votre portefeuille"
                : "fill up something"
            }
            onChange={(e) => {
              setFolioNameError(false);
              setFolioName(e.target.value);
            }}
            onBlur={handlerFolioName}
            value={folioName}
          />
        </div>
      </div>

      {emailError && (
        <div className="w3-panel w3-red">
          {language === "en" && (
            <>
              <h3>Error!</h3>
              <ul>
                <li>Please enter a valid email address</li>
              </ul>
            </>
          )}
          {language === "fr" && (
            <>
              <h3>Erreur!</h3>
              <ul>
                <li>Veuillez saisir une adresse électronique valide</li>
              </ul>
            </>
          )}
        </div>
      )}
      <div className="createFolioIconsInputs  ">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa fa-envelope"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="email"
            type="text"
            placeholder={
              language === "en"
                ? "Your Email"
                : language === "fr"
                ? "Votre courriel"
                : "fill up something"
            }
            onChange={(e) => {
              setEmailError(false);
              setEmail(e.target.value);
            }}
            onBlur={handlerEmail}
            value={email}
          />
        </div>
      </div>

      <div className="w3-row w3-section">
        {language === "en" && (
          <>
            If you want to blur your email like this{" "}
            <span className="bluryText">blurry text</span> and show it only with
            a code of your choice, then enter your code here up to 6 characters.
            Else, just leave it blank: &nbsp;
          </>
        )}
        {language === "fr" && (
          <>
            Si vous souhaitez brouiller votre courrier électronique comme suit{" "}
            <span className="bluryText">blurry text</span> et ne l'afficher
            qu'avec un code de votre choix, entrez votre code ici (jusqu'à 6
            caractères). Sinon, laissez le champ vide: &nbsp;
          </>
        )}
        <input
          name="showEmailCode"
          type="text"
          size="27"
          maxLength="6"
          autoComplete="whatever"
          placeholder={
            language === "en"
              ? "Code to show your email"
              : language === "fr"
              ? "Code pour afficher votre courriel"
              : "fill up something"
          }
          value={showEmailCode}
          onChange={(e) => {
            setShowEmailCode(e.target.value);
          }}
        />
      </div>

      {passwordError && (
        <div className="w3-panel w3-red">
          {language === "en" && (
            <>
              <h3>Error!</h3>
              <ul>
                <li>Password must be 4 to 14 characters. NO space"</li>
              </ul>
            </>
          )}
          {language === "fr" && (
            <>
              <h3>Error!</h3>
              <ul>
                <li>
                  {" "}
                  Le mot de passe doit comporter de 4 à 14 caractères. PAS
                  d'espace{" "}
                </li>
              </ul>
            </>
          )}
        </div>
      )}
      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa-solid fa-lock-open"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="password"
            type="password"
            placeholder={
              language === "en"
                ? "Password"
                : language === "fr"
                ? "Mot de passe"
                : "fill up something"
            }
            onChange={() => {
              setPasswordError(false);
            }}
            onBlur={handlerPassword}
          />
        </div>
      </div>

      {confirmPasswordError && (
        <div className="w3-panel w3-red">
          {language === "en" && (
            <>
              <h3>Error!</h3>
              <ul>
                <li>
                  Password and confirm password do NOT match. Please try again.
                </li>
              </ul>
            </>
          )}
          {language === "fr" && (
            <>
              <h3>Erreur!</h3>
              <ul>
                <li>
                  Le mot de passe et la confirmation du mot de passe ne
                  correspondent pas. Veuillez réessayer.
                </li>
              </ul>
            </>
          )}
        </div>
      )}
      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa-solid fa-lock"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="confirmPassword"
            type="password"
            placeholder={
              language === "en"
                ? "Confirm Password"
                : language === "fr"
                ? "Confirmer le mot de passe"
                : "fill up something"
            }
            onChange={(e) => {
              setConfirmPasswordError(false);
              setConfirmPassword(e.target.value);
            }}
            onBlur={handlerConfirmPassword}
          />
        </div>
      </div>

      <hr />

      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa fa-user"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="name"
            type="text"
            placeholder={
              language === "en"
                ? "Your Name"
                : language === "fr"
                ? "Votre nom"
                : "fill up something"
            }
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
      </div>

      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa fa-briefcase"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="job"
            type="text"
            placeholder={
              language === "en"
                ? "Your Job Title"
                : language === "fr"
                ? "Votre titre de poste"
                : "fill up something"
            }
            onChange={(e) => setJob(e.target.value)}
            value={job}
          />
        </div>
      </div>

      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa fa-home"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="location"
            type="text"
            placeholder={
              language === "en"
                ? "Your Location"
                : language === "fr"
                ? "Votre lieu d'habitation"
                : "fill up something"
            }
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          />
        </div>
      </div>

      <div className="createFolioIconsInputs">
        <div className="icon">
          <div className="twoFontAwesomeSymbols">
            <i
              className="w3-xxlarge fa fa-phone"
              style={{ color: accentColor }}
            ></i>
            <i className="fa-solid fa-asterisk w3-text-red"></i>
          </div>
        </div>
        <div className="input">
          <input
            className="w3-input w3-border"
            name="phone"
            type="text"
            placeholder={
              language === "en"
                ? "Your Phone"
                : language === "fr"
                ? "Votre téléphone"
                : "fill up something"
            }
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </div>
      </div>
      <div className="w3-row w3-section">
        {language === "en" && (
          <>
            If you want to blur your phone number like this{" "}
            <span className="bluryText">blurry text</span> and show it only with
            a code of your choice, then enter your code here up to 6 characters.
            Else, just leave it blank: &nbsp;
          </>
        )}
        {language === "fr" && (
          <>
            Si vous souhaitez brouiller votre téléphone comme suit{" "}
            <span className="bluryText">blurry text</span> et ne l'afficher
            qu'avec un code de votre choix, entrez votre code ici (jusqu'à 6
            caractères). Sinon, laissez le champ vide: &nbsp;
          </>
        )}

        <input
          name="showPhoneCode"
          size="29"
          maxLength="6"
          autoComplete="whatever"
          type="text"
          placeholder={
            language === "en"
              ? "Code to show your phone"
              : language === "fr"
              ? "Code pour afficher votre téléphone"
              : "fill up something"
          }
          value={showPhoneCode}
          onChange={(e) => {
            setShowPhoneCode(e.target.value);
          }}
        />
      </div>
      <hr />
      <div>
        <p>
          {language === "en" && "Choose your icon's or accent's color"}
          {language === "fr" &&
            "Choisissez la couleur de votre icône ou de votre accent"}
        </p>
        <ChromePicker
          width={200}
          height={200}
          color={accentColor}
          onChange={(color) => setAccentColor(color.hex)}
          // onChangeComplete={(color, event) => console.log(color)}
        />
        <br />
        <div className="divForDefaultColorButtons">
          <button
            className="w3-button w3-blue"
            onClick={() => setAccentColor("#4c95ce")}
          >
            {language === "en" && "Site's default blue color"}
            {language === "fr" && "Couleur bleue par défaut du site"}
          </button>

          <button
            className="w3-button w3-green"
            onClick={() => setAccentColor("#8cc23f")}
          >
            {language === "en" && "Site's default green color"}
            {language === "fr" && "Couleur verte par défaut du site"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
