import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ErrorComponent from "./ErrorComponent";
import fr from "date-fns/locale/fr";

const Project = ({ onSubmit, onCancel, language }) => {
  const [projectName, setProjectName] = useState("");
  const [projectYear, setProjectYear] = useState();
  const [projectDetails, setProjectDetails] = useState();
  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    setProjectYear(new Date());
  }, []);

  const handleSubmit = (event) => {
    let errorArrayTemp = [];

    projectName === "" &&
      language === "en" &&
      errorArrayTemp.push("Project name should NOT be empty");
    projectName === "" &&
      language === "fr" &&
      errorArrayTemp.push("Le nom du projet ne doit PAS être vide");

    setErrorArray(errorArrayTemp);
    if (errorArrayTemp.length > 0) return;
    let obj = {};
    obj.projectName = projectName;
    obj.projectYear = projectYear.getFullYear();
    obj.projectDetails = projectDetails;
    console.log(obj);
    onSubmit(obj);
  };

  return (
    <div>
      {errorArray.length > 0 && (
        <ErrorComponent title="Work Experience" errorArray={errorArray} language={language}/>
      )}
      <div>
        <p>
          {language === "en" && "Project Name"}
          {language === "fr" && "Nom du projet"}
        </p>
        <input
          className="w3-input w3-border"
          type="text"
          name="name"
          onChange={(event) => setProjectName(event.target.value)}
        />
      </div>
      <br />
      <div>
        {language === "en" && "Project Year "}
        {language === "fr" && "Année du projet "}

        <DatePicker
          selected={projectYear}
          // renderYearContent={renderYearContent}
          showYearPicker
          dateFormat="yyyy"
          onChange={(date) => setProjectYear(date)}
          closeOnScroll={true}
          className="w3-input w3-border"
        />
      </div>

      <br />
      <p>
        {language === "en" && "Details"}
        {language === "fr" && "Détails"}
      </p>
      <CKEditor
        editor={ClassicEditor}
        placeholder="Write message"
        data=""
        config={{
          // plugins: [Indent, IndentBlock],
          toolbar: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "bulletedList",
            "numberedList",
          ],
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setProjectDetails(data);
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
      <br />
      <div className="besideEachOther">
        <button onClick={handleSubmit} className="w3-button w3-gray">
          {language === "en" && <>Add new project</>}
          {language === "fr" && <>Ajouter un nouveau projet</>}
        </button>
        <button onClick={onCancel} className="w3-button w3-red">
          {language === "en" && "Cancel"}
          {language === "fr" && "Annuler"}
        </button>
      </div>
    </div>
  );
};

export default Project;
