import React from "react";
import MainUI from "./MainUI";
import Nav from "../components/Nav";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <MainUI>
      <Nav />
      <div
        style={{
          width: "90%",
          maxWidth: "600px",
          margin: "auto",
          marginTop: 40,
        }}
        className="w3-center w3-container w3-card w3-padding  w3-light-grey"
      >
        <div>
          <ul style={{ listStyle: "none" }}>
            <br />
            <li>
              <span
                className="w3-xxxlarge w3-text-green"
                style={{ fontWeight: "bold" }}
              >
                P
              </span>
              icture this: a cool online space that's all about you and what
              you're good at.{" "}
            </li>
            <br />
            <li>
              <span
                className="w3-xxxlarge w3-text-green"
                style={{ fontWeight: "bold" }}
              >
                I
              </span>
              t's like your own resume but way cooler. You can show off your
              projects, skills, education, and experiences in a way that's easy
              for anyone to check out.{" "}
            </li>
            <br />
            <li>
              <span
                className="w3-xxxlarge w3-text-green"
                style={{ fontWeight: "bold" }}
              >
                T
              </span>
              his is your chance to stand out and get noticed by potential
              employers or clients. Plus, it's online, so you're open for
              opportunities 24/7. It's like having your own personal billboard
              on the internet.{" "}
            </li>
          </ul>
        </div>
        <div>
          <Link to="/createFolio">
            <button
              className="w3-tag w3-blue w3-xlarge w3-padding  w3-card-4"
              style={{ cursor: "pointer", margin: 49 }}
            >
              CREATE YOUR PORTFOLIO
            </button>
          </Link>
        </div>
      </div>
    </MainUI>
  );
};

export default Home;
