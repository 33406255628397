import React from "react";
import logo from "../media/Logo-no-bg.png";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div>
      <div className="w3-margin">
        <Link to="/">
          <img src={logo} style={{ width: 200 }} />
        </Link>
      </div>
      <div className="w3-bar w3-border w3-light-grey">
        <Link to="/" className="w3-bar-item w3-button w3-mobile">
          Home
        </Link>
        <Link
          to="/login"
          style={{ background: "#8cc23f", color: "#fff" }}
          className="w3-bar-item w3-button w3-right w3-card  w3-mobile"
        >
          <strong> Log in </strong>
        </Link>
        <Link
          to="/createFolio"
          style={{ background: "#4c95ce", color: "#fff" }}
          className="w3-bar-item w3-button w3-right w3-card w3-mobile"
        >
          Create your portfolio
        </Link>
      </div>
    </div>
  );
};

export default Nav;
