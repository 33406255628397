import parse from "html-react-parser";

const PublicFolioWork = ({ workArr, accentColor, accentShade }) => {
  return (
    <div className="w3-container w3-card w3-white w3-margin-bottom">
      <h2 className="w3-text-grey w3-padding-16">
        <i
          className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge"
          style={{ color: accentColor }}
        ></i>
        Experience
      </h2>

      {workArr
        .slice(0)
        .reverse()
        .map((work) => (
          <div className="w3-container" key={work.id}>
            <h5 className="w3-opacity">
              <b>
                {work.title} {work.company && "/"} {work.company}{" "}
              </b>
            </h5>
            <h6 style={{ color: accentColor, fontWeight: "bold" }}>
              <i className="fa fa-calendar fa-fw w3-margin-right"></i>
              {work.fromMonth} {work.fromYear} -{" "}
              {work.isCurrent ? (
                <span
                  className="w3-tag w3-round"
                  style={{
                    backgroundColor: accentColor,
                    color: accentShade === "dark" ? "white" : "black",
                  }}
                >
                  Current
                </span>
              ) : (
                `${work.toMonth} ${work.toYear}`
              )}
            </h6>
            <div>{parse(work.workDetail)}</div>
            <hr />
          </div>
        ))}
    </div>
  );
};

export default PublicFolioWork;
