import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateFolio from "./CreateFolio";
import axios from "axios";
import ErrorPage from "./ErrorPage";

const axiosAPI = process.env.REACT_APP_API_URL;

const ClientDashboard = () => {
  const [folioObj, setFolioObj] = useState(null);
  const [error, setError] = useState(false);

  let location = useLocation();
  const navigate = useNavigate();

  const folioName = location?.state?.folioName;

  useEffect(() => {
    if (!folioName) navigate("/login");
  }, [folioName]);

  // console.log(folioName);
  // if (!folioName) navigate("/login");

  useEffect(() => {
    setError(false);
    const getFolioObjJson = async (folioName) => {
      try {
        const result = await axios.get(`${axiosAPI}getFolioObjJson`, {
          params: { folioName },
        });
        // console.log(result);
        if (result.data === "404 NOT FOUND") throw new Error();
        setFolioObj(await result.data);

        // if (typeof folioObj === "object") folioObj.error = false;
        // console.log(folioObj);
      } catch (error) {
        // console.log("error in Axios ", error);
        setError(true);
      }
    };
    if (folioName) getFolioObjJson(folioName);
  }, [folioName]);

  if (error)
    return (
      <>
        <ErrorPage />
      </>
    );

  return (
    <div>
      {folioObj ? (
        <>
          <CreateFolio edit={true} folioObj={folioObj} />
        </>
      ) : (
        // <h1>I am in </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div>
            {/* <h3>Loading..</h3> */}
            <i className="fa-solid fa-spinner fa-spin fa-5x"></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDashboard;
