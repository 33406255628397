import React from "react";
import MainUI from "./MainUI";
import Nav from "../components/Nav";
import { useRouteError } from "react-router-dom";
import noPicture from "../media/no_picture.png";

const FolioNotFound = () => {
  const error = useRouteError();
  // console.error(error);

  return (
    <MainUI>
      <Nav />
      <div className="w3-center">
        <img src={noPicture} style={{ marginTop: 20 }} />
        <p style={{ fontSize: "90px", marginTop: -10 }}>Oops!</p>
        <p style={{ fontSize: "30px", margin: -60 }}>Portfolio NOT found.</p>
        <i>{error?.statusText || error?.message}</i>
      </div>
    </MainUI>
  );
};

export default FolioNotFound;
