import { useState, useEffect } from "react";
import no_picture from "../../media/no_picture.png";

const PublicFolioPersonalInfo = ({
  picture,
  name,
  job,
  location,
  email,
  phone,
  showEmailCode,
  showPhoneCode,
  accentColor,
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  useEffect(() => {
    if (!showEmailCode) setShowEmail(true);
    if (!showPhoneCode) setShowPhone(true);
  }, [showEmailCode, showPhoneCode]);

  function handleEmailCodeInput(e) {
    e.preventDefault();
    if (e.target.value === showEmailCode) {
      setShowEmail(true);
    }
  }

  function handlePhoneCodeInput(e) {
    e.preventDefault();
    if (e.target.value === showPhoneCode) {
      setShowPhone(true);
    }
  }
  return (
    <>
      <div className="w3-display-container">
        <img
          src={picture ? picture : no_picture}
          style={{ width: `100%` }}
          alt="avatar"
        />
      </div>
      <div className="w3-container">
        <h2>{name}</h2>
        <p>
          <i
            className="fa fa-briefcase fa-fw w3-margin-right w3-large"
            style={{ color: accentColor }}
          ></i>
          {job}
        </p>
        <p>
          <i
            className="fa fa-home fa-fw w3-margin-right w3-large "
            style={{ color: accentColor }}
          ></i>
          {location}
        </p>
        <p>
          <i
            className="fa fa-envelope fa-fw w3-margin-right w3-large"
            style={{ color: accentColor }}
          ></i>
          {showEmail ? (
            email
          ) : (
            <span className="bluryText">bluryEmail@MindYourBusiness.com</span>
          )}
          <br />

          {!showEmail && (
            <label>
              Enter code to show email{" "}
              <input
                type="text"
                size="6"
                maxLength="6"
                onChange={handleEmailCodeInput}
              />
            </label>
          )}
        </p>
        <p>
          <i
            className="fa fa-phone fa-fw w3-margin-right w3-large"
            style={{ color: accentColor }}
          ></i>
          {showPhone ? (
            phone
          ) : (
            <span className="bluryText">1.800.MindYourBusiness</span>
          )}
          <br />
          {!showPhone && (
            <label>
              Enter code to show phone{" "}
              <input
                type="text"
                size="6"
                maxLength="6"
                onChange={handlePhoneCodeInput}
              />
            </label>
          )}
        </p>
        <hr />
      </div>
    </>
  );
};

export default PublicFolioPersonalInfo;
