const PublicFolioExpertise = ({ expertisesArr, accentColor, accentShade }) => {
  return (
    <div className="w3-container">
      <p className="w3-large">
        <b>
          <i
            className="fa-solid fa-medal fa-fw w3-margin-right"
            style={{ color: accentColor }}
          ></i>
          Expertise
        </b>
      </p>
      {expertisesArr.map((skill) => (
        <div key={skill.id}>
          <p>{skill.name}</p>
          <div
            className="w3-light-grey w3-round-xlarge"
            style={{ marginTop: "-15px" }}
          >
            <div
              className="w3-container w3-center w3-round-xlarge center"
              style={{
                backgroundColor: accentColor,
                width: `${skill.level}%`,
                height: 23,
              }}
            >
              <span
                style={{
                  color: accentShade === "dark" ? "white" : "black",
                  fontWeight: "bold",
                  verticalAlign: "center",
                  alignContent: "center",
                }}
              >
                {skill.level}%
              </span>
            </div>
          </div>
        </div>
      ))}
      <hr />
    </div>
  );
};

export default PublicFolioExpertise;
