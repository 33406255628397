import parse from "html-react-parser";

const PublicFolio_Education = ({ educationArr, accentColor, accentShade }) => {
  return (
    <div className="w3-container w3-card w3-white w3-margin-bottom">
      <h2 className="w3-text-grey w3-padding-16">
        <i
          className="fa fa-certificate fa-fw w3-margin-right w3-xxlarge"
          style={{ color: accentColor }}
        ></i>
        Education
      </h2>

      {educationArr
        .slice(0)
        .reverse()
        .map((education) => (
          <div className="w3-container" key={education.id}>
            <h5 className="w3-opacity">
              <b>{education.title}</b>
            </h5>
            <h6 style={{ color: accentColor, fontWeight: "bold" }}>
              <i className="fa fa-calendar fa-fw w3-margin-right"></i>
              {education.fromMonth} {education.fromYear} -{" "}
              {education.isCurrent ? (
                <span
                  className="w3-tag w3-round"
                  style={{
                    backgroundColor: accentColor,
                    color: accentShade === "dark" ? "white" : "black",
                  }}
                >
                  Current
                </span>
              ) : (
                `${education.toMonth} ${education.toYear}`
              )}
            </h6>
            <div>{parse(education.workDetail)}</div>
            <hr />
          </div>
        ))}
    </div>
  );
};

export default PublicFolio_Education;
