import React from "react";

const ErrorComponent = ({ errorArray, language }) => {
  return (
    <div className="w3-panel w3-red">
      <h3>
        {language === "en" && "Error!"}
        {language === "fr" && "Erreur!"}
      </h3>

      {errorArray.length > 0 && (
        <ul>
          {errorArray.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ErrorComponent;
