import { useLocation, Link } from "react-router-dom";
import MainUI from "./MainUI";
import Nav from "../components/Nav";

const FolioCreatedSuccessfully = () => {
  let location = useLocation();

  const folioName = location.state.folioName;
  return (
    <MainUI>
      <Nav />
      {/* style={{ backgroundColor: "#8cc23f" }} */}
      <div className="w3-panel w3-green">
        <h1>SUCCESS!</h1>
        <h3>Portfolio Created Successfully! {folioName} </h3>
      </div>
      <Link to={`/${folioName}`}>Visit your portfolio page</Link>
      <br />
      <br />
      <Link to="/"> Home page </Link>
    </MainUI>
  );
};

export default FolioCreatedSuccessfully;
