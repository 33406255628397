import { Link } from "react-router-dom";
import logo from "../../media/Logo-no-bg.png";

const PublicFolioFooter = ({
  accentColor,
  accentShade,
  name,
  folioName,
  mediaFaceBook,
  mediaLinkedIn,
  mediaYouTube,
  mediaTwitter,
  mediaInstagram,
}) => {
  console.log(mediaFaceBook);
  return (
    <div className="w3-margin-bottom">
      <footer
        className="w3-container w3-center"
        style={{
          marginTop: "50px",
          backgroundColor: accentColor,
          color: accentShade === "dark" ? "white" : "black",
          padding: "33px 0px",
        }}
      >
        <div style={{ marginBottom: "0px" }}>
          <h3 className="w3-center">{name} </h3>
          <h4> FolioUp.me/{folioName}</h4>
        </div>
        {(mediaFaceBook ||
          mediaLinkedIn ||
          mediaYouTube ||
          mediaTwitter ||
          mediaInstagram) && (
          <div style={{ marginTop: "37px", marginBottom: "7px" }}>
            See more of me on social media
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "19px",
            marginBottom: "0px",
            flexWrap: "wrap",
          }}
        >
          {mediaFaceBook && (
            <>
              <a href={mediaFaceBook} target="_blank">
                <i className="fa-brands fa-square-facebook fa-3x"></i>
              </a>
            </>
          )}

          {mediaLinkedIn && (
            <>
              <a href={mediaLinkedIn} target="_blank">
                <i className="fa-brands fa-linkedin fa-3x"></i>
              </a>
            </>
          )}
          {mediaYouTube && (
            <>
              <a href={mediaYouTube} target="_blank">
                <i className="fa-brands fa-square-youtube fa-3x"></i>
              </a>
            </>
          )}
          {mediaTwitter && (
            <>
              <a href={mediaTwitter} target="_blank">
                <i className="fa-brands fa-square-x-twitter fa-3x"></i>
              </a>
            </>
          )}
          {mediaInstagram && (
            <>
              <a href={mediaInstagram} target="_blank">
                <i className="fa-brands fa-square-instagram fa-3x"></i>
              </a>
            </>
          )}
        </div>
      </footer>
      <div className="w3-center" style={{ padding: "23px" }}>
        <Link to="/" target="_blank">
          <img
            alt="logo"
            src={logo}
            className="w3-hover-grayscale w3-padding-24"
            style={{ width: "300px" }}
          />
        </Link>
      </div>
    </div>
  );
};

export default PublicFolioFooter;
