import MainUI from "./MainUI";
import Nav from "../components/Nav";
import spinner from "../media/logo_heartbeat.gif";

const SpinnerOfDoom = () => {
  return (
    <MainUI>
      <Nav />
      <div className="w3-center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <img src={spinner} />
          {/* <h3>Loading..</h3> */}
          {/* <i className="fa-solid fa-spinner fa-spin fa-9x"></i> */}
        </div>
      </div>
    </MainUI>
  );
};

export default SpinnerOfDoom;
