import React, { useState, useRef, useEffect } from "react";
import Nav from "./Nav";
import MainUI from "../pages/MainUI";
import axios from "axios";

const axiosAPI = process.env.REACT_APP_API_URL;

const ProtectingCreateFolio = ({ children }) => {
  const [accessCode, setAccessCode] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [code, setCode] = useState(null);
  const [error, setError] = useState(false);
  const codeInput = useRef(null);

  useEffect(() => {
    getAccessCodeFromDB();
  }, []);

  if (loggedIn) return children;

  const handleKeyStroke = (event) => {
    // console.log("inside keystroke: ", event);
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      // setIsSubmitting(true);
      testLoggedIn();
    }
  };
  const getAccessCodeFromDB = async () => {
    try {
      await axios.get(`${axiosAPI}getAccessCode/`).then((response) => {
        const accessCode = response.data.toString();
        // console.log(accessCode);
        setAccessCode(accessCode);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const testLoggedIn = async () => {
    if (code === accessCode) setLoggedIn(true);
    else {
      codeInput.current.value = "";
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <MainUI>
      <Nav />
      <div className=" w3-light-grey w3-margin-top w3-padding">
        <div style={{ textAlign: "center" }}>
          <h1 className="w3-padding">Access code</h1>
          {error && (
            <div className="w3-panel w3-red">
              <h2>Error!</h2>
              <h3>Wrong Code. Try again or get the correct code </h3>
            </div>
          )}
          <label>Enter code to create your online portfolio</label>
          <br />
          <input
            type="text"
            style={{ width: "400px", maxWidth: "80%" }}
            onChange={(event) => setCode(event.target.value)}
            ref={codeInput}
            onKeyDown={(e) => handleKeyStroke(e)}
          />
          <br />
          <br />
          <button
            type="button"
            onClick={testLoggedIn}
            className="w3-button  w3-section w3-blue w3-ripple w3-padding"
          >
            Submit Code
          </button>
        </div>
      </div>
    </MainUI>
  );
};

export default ProtectingCreateFolio;
