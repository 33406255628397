import React from "react";

const PublicFolioSkills = ({ skills, accentColor, accentShade }) => {
  return (
    <div className="w3-container">
      <p className="w3-large">
        <b>
          <i
            className="fa-solid fa-star-of-life  fa-fw w3-margin-right"
            style={{ color: accentColor }}
          ></i>
          Skills
        </b>
      </p>
      <div className="publicContainer">
        {skills.map((item, index) => (
          <div
            key={item.id}
            className="itemInContainer"
            style={{ backgroundColor: accentColor }}
          >
            <span
              style={{
                color: accentShade === "dark" ? "white" : "black",
                verticalAlign: "center",
                alignContent: "center",
              }}
            >
              {" "}
              {item.label}
            </span>
          </div>
        ))}
      </div>
      <hr />
    </div>
  );
};

export default PublicFolioSkills;
