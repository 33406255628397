import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ErrorComponent from "./ErrorComponent";
import fr from "date-fns/locale/fr";

const WorkExperienceAndEducation = ({
  onSubmit,
  onCancel,
  param,
  language,
}) => {
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [fromYear, setFromYear] = useState();
  const [fromMonth, setFromMonth] = useState();
  const [toYear, setToYear] = useState();
  const [toMonth, setToMonth] = useState();
  const [isCurrent, setIsCurrent] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [errorArray, setErrorArray] = useState([]);
  const [workDetail, setWorkDetail] = useState("");

  let localeForPicker = "en";
  let localForJSDate = "default";
  if (language === "fr") {
    localeForPicker = "fr";
    registerLocale(localeForPicker, fr);
    localForJSDate = "fr-CA";
  }

  useEffect(() => {
    setFromDate(new Date());
    setToDate(new Date());
    setFromYear(new Date().getFullYear());
    setFromMonth(new Date().toLocaleString(localForJSDate, { month: "long" }));
    setToYear(new Date().getFullYear());
    // setToMonth(new Date().toLocaleString("default", { month: "long" }));
    setToMonth(new Date().toLocaleString(localForJSDate, { month: "long" }));
  }, []);

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const onChangeDatePicker = (date, from_to) => {
    if (from_to === "from") {
      setFromDate(date);
      setFromYear(new Date(date).getFullYear());
      setFromMonth(
        new Date(date).toLocaleString(localForJSDate, { month: "long" })
      );
    } else if (from_to === "to") {
      setToDate(date);
      setToYear(new Date(date).getFullYear());
      setToMonth(
        new Date(date).toLocaleString(localForJSDate, { month: "long" })
      );
    }
  };

  const handleSubmit = (event) => {
    let errorArrayTemp = [];
    if (!isCurrent && toDate < fromDate)
      errorArrayTemp.push("From date should be before the To date");
    if (title === "")
      errorArrayTemp.push(
        `${param}` === "work"
          ? "Title should NOT be empty"
          : "School/Institute should NOT be empty"
      );
    if (workDetail === "")
      errorArrayTemp.push(
        `${param}` === "work"
          ? "Work detail should NOT be empty"
          : "Education detail should NOT be empty"
      );
    setErrorArray(errorArrayTemp);
    if (errorArrayTemp.length > 0) return;
    let obj = {};
    obj.title = title;
    obj.company = company;
    obj.fromMonth = fromMonth;
    obj.fromYear = fromYear;
    obj.toMonth = toMonth;
    obj.toYear = toYear;
    obj.isCurrent = isCurrent;
    obj.workDetail = workDetail;
    onSubmit(obj);
  };

  return (
    <div>
      {errorArray.length > 0 && (
        <ErrorComponent
          title="Work Experience"
          errorArray={errorArray}
          language={language}
        />
      )}
      {/* {param === "work" ? "Title" : "School/Institute"}{" "} */}
      {param === "work" && language === "en" && "Title"}
      {param === "work" && language === "fr" && "Titre"}
      {param === "education" && language === "en" && "School/Institute"}
      {param === "education" && language === "fr" && "École/institut"}
      <input
        className="w3-input w3-border"
        type="text"
        name="title"
        onChange={(event) => setTitle(event.target.value)}
      />
      {param === "work" && language === "en" && "Company"}
      {param === "work" && language === "fr" && "Entreprise"}
      {param === "work" && (
        <>
          <input
            className="w3-input w3-border"
            type="text"
            name="company"
            onChange={(event) => setCompany(event.target.value)}
          />
          <br />
        </>
      )}
      <br />
      {language === "en" && "From"}
      {language === "fr" && "De"}{" "}
      <DatePicker
        locale={localeForPicker}
        className="w3-input w3-border"
        selected={fromDate}
        showIcon
        renderMonthContent={renderMonthContent}
        showMonthYearPicker
        dateFormat="MM-yyyy"
        onChange={(date) => onChangeDatePicker(date, "from")}
        closeOnScroll={true}
      />
      <br />
      {language === "en" && "To"}
      {language === "fr" && "à"}
      {"   "}
      <label>
        {" "}
        <input
          type="checkbox"
          onChange={(e) => setIsCurrent(e.target.checked)}
        />{" "}
        {language === "en" && "Current (Till now)"}
        {language === "fr" && "Actuel (jusqu'à présent)"}
      </label>
      <br />
      {!isCurrent && (
        <DatePicker
          locale={localeForPicker}
          className="w3-input w3-border"
          selected={toDate}
          showIcon
          renderMonthContent={renderMonthContent}
          showMonthYearPicker
          dateFormat="MM-yyyy"
          format="MM/yyyy"
          onChange={(date) => onChangeDatePicker(date, "to")}
          closeOnScroll={true}
        />
      )}
      <br />
      <p>
        {language === "en" && "Details"}
        {language === "fr" && "Détails"}
      </p>
      <CKEditor
        editor={ClassicEditor}
        placeholder="Write message"
        data=""
        config={{
          // plugins: [Indent, IndentBlock],
          toolbar: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "bulletedList",
            "numberedList",
          ],
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setWorkDetail(data);
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
      <br />
      <div className="besideEachOther">
        <button onClick={handleSubmit} className="w3-button w3-gray">
          {language === "en" && (
            <>Add new {param === "work" ? "work experience" : "education"}</>
          )}
          {language === "fr" && (
            <>
              Ajouter un nouveau {param === "work" ? "expérience" : "éducation"}
            </>
          )}
        </button>
        <button onClick={onCancel} className="w3-button w3-red">
          {language === "en" && "Cancel"}
          {language === "fr" && "Annuler"}
        </button>
      </div>
      {/* <div>
        <h2> Content </h2>
        <p>{workDetail}</p>
      </div>
      <div>
        <h2> Content after parsing </h2>
        <p>{parse(workDetail)}</p>
      </div> */}
    </div>
  );
};

export default WorkExperienceAndEducation;
