const MainUI = ({ children }) => {
  return (
    <div
      className="w3-content w3-container w3-margin-top"
      style={{ maxWidth: 1400 }}
    >
      {children}
    </div>
  );
};

export default MainUI;
