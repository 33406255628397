import { useEffect, useState } from "react";
import { useLoaderData, Link, useNavigation } from "react-router-dom";
import axios from "axios";
import PublicFolioPersonalInfo from "../components/publicFolio/PublicFolioPersonalInfo";
import PublicFolioExpertise from "../components/publicFolio/PublicFolioExpertise";
import PublicFolioSkills from "../components/publicFolio/PublicFolioSkills";
import PublicFolioLanguages from "../components/publicFolio/PublicFolioLanguages";
import PublicFolioWork from "../components/publicFolio/PublicFolioWork";
import PublicFolioEducation from "../components/publicFolio/PublicFolioEducation";
import PublicFolioProjects from "../components/publicFolio/PublicFolioProjects";
import PublicFolioFooter from "../components/publicFolio/PublicFolioFooter";
import FolioNotFound from "./FolioNotFound";
import lightOrDark from "../utils/lightOrDark";

const axiosAPI = process.env.REACT_APP_API_URL;

export async function loader({ params }) {
  const folioName = await params.folioName.toLowerCase();
  let folioObj = {};
  try {
    const result = await axios.get(`${axiosAPI}getFolioObjJson`, {
      params: { folioName },
    });
    if (result.data === "404 NOT FOUND") throw new Error();
    folioObj = result.data;
    if (typeof folioObj === "object") folioObj.error = false;
    return folioObj;
  } catch (error) {
    // console.log("ERROR", error);
    return { error };
  }
}

const PublicFolio = () => {
  const folioObj = useLoaderData();
  const { state } = useNavigation();

  const [accentShade, setAccentShade] = useState("light");

  useEffect(() => {
    // console.log(lightOrDark(folioObj.accentColor));
    if (folioObj.accentColor) {
      setAccentShade(lightOrDark(folioObj.accentColor));
    }
  }, [folioObj.accentColor]);

  // console.log(folioObj);
  if (state !== "idle") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <h3>Loading..</h3>
          <i className="fa-solid fa-spinner fa-spin fa-5x"></i>
        </div>
      </div>
    );
  }

  if (folioObj.error) {
    return <FolioNotFound />;
  }
  return (
    <div className="w3-light-grey">
      {/* Page Container */}
      <div className="w3-content w3-margin-top" style={{ maxWidth: 1400 }}>
        {/* The Grid */}
        <div className="w3-row-padding">
          {/* Left Column */}
          <div className="w3-third">
            <div className="w3-white w3-text-grey w3-card-4">
              <PublicFolioPersonalInfo
                picture={folioObj.picture}
                name={folioObj.name}
                job={folioObj.job}
                email={folioObj.email}
                phone={folioObj.phone}
                showEmailCode={folioObj.showEmailCode}
                showPhoneCode={folioObj.showPhoneCode}
                location={folioObj.location}
                accentColor={
                  folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                }
                accentShade={accentShade}
              />

              {folioObj.expertises?.length > 0 && (
                <PublicFolioExpertise
                  expertisesArr={folioObj.expertises}
                  accentColor={
                    folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                  }
                  accentShade={accentShade}
                />
              )}

              {folioObj.skills?.length > 0 && (
                <PublicFolioSkills
                  skills={folioObj.skills}
                  accentColor={
                    folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                  }
                  accentShade={accentShade}
                />
              )}
              <br />
              {folioObj.languages.length > 0 && (
                <PublicFolioLanguages
                  languagesArr={folioObj.languages}
                  accentColor={
                    folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                  }
                  accentShade={accentShade}
                />
              )}
            </div>
            <br />
            {/* End Left Column */}
          </div>

          {/* <!-- Right Column --> */}
          <div className="w3-twothird">
            {folioObj.workExperiences.length > 0 && (
              <PublicFolioWork
                workArr={folioObj.workExperiences}
                accentColor={
                  folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                }
                accentShade={accentShade}
              />
            )}
            {folioObj.projects?.length > 0 && (
              <PublicFolioProjects
                projectsArr={folioObj.projects}
                accentColor={
                  folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                }
                accentShade={accentShade}
              />
            )}
            {folioObj.education.length > 0 && (
              <PublicFolioEducation
                educationArr={folioObj.education}
                accentColor={
                  folioObj.accentColor ? folioObj.accentColor : "#4c95ce"
                }
                accentShade={accentShade}
              />
            )}

            {/* <!-- End Right Column --> */}
          </div>

          {/* <!-- End Grid --> */}
        </div>

        {/* <!-- End Page Container --> */}
      </div>
      <PublicFolioFooter
        accentColor={folioObj.accentColor ? folioObj.accentColor : "#4c95ce"}
        accentShade={accentShade}
        name={folioObj.name}
        folioName={folioObj.folioName}
        mediaFaceBook={folioObj.mediaFaceBook}
        mediaLinkedIn={folioObj.mediaLinkedIn}
        mediaYouTube={folioObj.mediaYouTube}
        mediaTwitter={folioObj.mediaTwitter}
        mediaInstagram={folioObj.mediaInstagram}
      />
    </div>
  );
};

export default PublicFolio;
