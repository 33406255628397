import React from "react";
import MainUI from "./MainUI";
import Nav from "../components/Nav";

const Page404 = () => {
  return (
    <MainUI>
      <Nav />
      <div className="w3-center">
        <p style={{ fontSize: "200px", margin: "0" }}>404</p>
        <p style={{ fontSize: "30px", margin: "0" }}>page not found</p>
      </div>
    </MainUI>
  );
};

export default Page404;
