import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import Home from "./pages/Home";
import LogIn from "./pages/LogIn";
import ErrorPage from "./pages/ErrorPage";
import CreateFolio from "./pages/CreateFolio";
import ProtectingCreateFolio from "./components/ProtectingCreateFolio.jsx";
import ImageUpload from "./components/ImageUpload";
import FolioCreatedSuccessfully from "./pages/FolioCreatedSuccessfully";
import ClientDashboard from "./pages/ClientDashboard";
import Page404 from "./pages/Page404";
import SpinnerOfDoom from "./pages/SpinnerOfDoom";
import PublicFolio, { loader as folioNameLoader } from "./pages/PublicFolio";
import { UserContext } from "./pages/UserContext.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <LogIn />,
  },
  {
    path: "/createFolio",
    element: (
      <ProtectingCreateFolio>
        <CreateFolio />
      </ProtectingCreateFolio>
    ),
  },
  {
    path: "/imageUpload",
    element: <ImageUpload />,
  },
  {
    path: "/folioCreatedSuccessfully",
    element: <FolioCreatedSuccessfully />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/:folioName",
    element: <PublicFolio />,
    loader: folioNameLoader,
  },
  {
    path: "/clientDashboard/",
    element: <ClientDashboard />,
  },
  {
    path: "/*",
    element: <Page404 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserContext.Provider value={{ name: "Mohamad" }}>
    <RouterProvider router={router} fallbackElement={<SpinnerOfDoom />} />
  </UserContext.Provider>
);
